import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BG from "../assets/2015/11/MAZD0294-Mazda-Foundation-Website-V4_03.jpg"
import { Link } from "gatsby"

const FoundationObjectives = () => (
  <Layout page="404">
    <SEO title="Page not found" />
    <div
      id="404"
      style={{
        background: `url(${BG}) repeat`,
      }}
    >
      <div id="title" className="green-bg vertical-padding-smaller center">
        <div className="container">
          <div className="horizontal-padding">
            <h1>Error 404</h1>
          </div>
        </div>
      </div>
      <div className="vertical-padding center">
        <div className="container">
          <div id="about" className="horizontal-padding">
            <h1 className="large">Page not found</h1>
            <p>Sorry, but we couldn't find the content you were looking for.</p>
            <Link to="/" className="button">Back to home</Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default FoundationObjectives
